<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="mb-4 h4 font-weight-bold text-color-green">
          Pemeriksaan Perawat (Anamnesis)
        </div>
        <div class="card">
          <div class="card-body background-blue">
            <div class="">
              <div class="row">
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Nama
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Tanggal Lahir
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Usia
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Jenis Kelamin
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Cara Pembayaran
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.payment_method }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Dokter
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Doctor.User.name }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-xl-4">
                  <table>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        No. RM
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 120px; height: 32px; padding-right: 8px"
                      >
                        Status
                      </td>
                      <td>:</td>
                      <td style="padding-left: 8px">
                        {{ this.patientVisitData.status }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col-xl-4">
              <div class="card">
                <div class="card-body">
                  <table>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Nama Lengkap
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.name }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Jenis Kelamin
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.gender }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Tempat Lahir
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.birth_place }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Tanggal Lahir
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.birth_date }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Usia
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.age }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Rekam Medis
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.medical_record_no }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        NIK
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.NIK }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Cara Pembayaran
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.payment_method }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        this.patientVisitData.Patient.payment_method ===
                        'Asuransi'
                      "
                    >
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Nama Asuransi
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.insurance_name }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        this.patientVisitData.Patient.payment_method !==
                        'Mandiri'
                      "
                    >
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Asuransi
                      </td>
                      <td
                        style="padding-left: 15px"
                        v-if="
                          this.patientVisitData.Patient.payment_method === 'JKN'
                        "
                      >
                        {{ this.patientVisitData.Patient.bpjs_no }}
                      </td>
                      <td
                        style="padding-left: 15px"
                        v-if="
                          this.patientVisitData.Patient.payment_method ===
                          'Asuransi'
                        "
                      >
                        {{ this.patientVisitData.Patient.insurance_no }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Telepon Rumah
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.telephone_no"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.telephone_no }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        No. Telepon Selular
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.phone_no"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.phone_no }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Email
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.email"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.email }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Alamat KTP
                      </td>
                      <td
                        style="padding-left: 15px"
                        v-if="
                          this.patientVisitData.Patient.identity_nation ===
                          'Indonesia'
                        "
                      >
                        {{ this.patientVisitData.Patient.identity_address }}, RT
                        {{ this.patientVisitData.Patient.identity_RT }}, RW
                        {{ this.patientVisitData.Patient.identity_RW }},
                        Kelurahan
                        {{ this.patientVisitData.Patient.identity_village }},
                        Kecamatan
                        {{ this.patientVisitData.Patient.identity_district }},
                        {{ this.patientVisitData.Patient.identity_regency }},
                        {{ this.patientVisitData.Patient.identity_province }},
                        {{ this.patientVisitData.Patient.identity_nation }},
                        {{ this.patientVisitData.Patient.identity_postal_code }}
                      </td>
                      <td style="padding-left: 15px" v-else>
                        {{ this.patientVisitData.Patient.identity_address }},
                        {{ this.patientVisitData.Patient.identity_nation }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Alamat Domisili
                      </td>
                      <td
                        style="padding-left: 15px"
                        v-if="
                          this.patientVisitData.Patient.domicile_nation ===
                          'Indonesia'
                        "
                      >
                        {{ this.patientVisitData.Patient.domicile_address }}, RT
                        {{ this.patientVisitData.Patient.domicile_RT }}, RW
                        {{ this.patientVisitData.Patient.domicile_RW }},
                        Kelurahan
                        {{ this.patientVisitData.Patient.domicile_village }},
                        Kecamatan
                        {{ this.patientVisitData.Patient.domicile_district }},
                        {{ this.patientVisitData.Patient.domicile_regency }},
                        {{ this.patientVisitData.Patient.domicile_province }},
                        {{ this.patientVisitData.Patient.domicile_nation }},
                        {{ this.patientVisitData.Patient.domicile_postal_code }}
                      </td>
                      <td style="padding-left: 15px" v-else>
                        {{ this.patientVisitData.Patient.domicile_address }},
                        {{ this.patientVisitData.Patient.domicile_nation }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Nama Ibu Kandung
                      </td>
                      <td style="padding-left: 15px">
                        {{
                          this.patientVisitData.Patient.biological_mother_name
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Agama
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.religion }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Suku
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.ethnic"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.ethnic }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Bahasa
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.language }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Pendidikan
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.education"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.education }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Pekerjaan
                      </td>
                      <td
                        v-if="this.patientVisitData.Patient.job"
                        style="padding-left: 15px"
                      >
                        {{ this.patientVisitData.Patient.job }}
                      </td>
                      <td v-else style="padding-left: 15px">-</td>
                    </tr>
                    <tr>
                      <td
                        style="width: 130px; height: 32px; padding-right: 15px"
                      >
                        Status Pernikahan
                      </td>
                      <td style="padding-left: 15px">
                        {{ this.patientVisitData.Patient.martial_status }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-xl-8">
              <div class="card">
                <div class="card-body">
                  <div style="display: flex; justify-content: center">
                    <a-steps
                      :current="activeKey - 1"
                      class="steps"
                      style="width: 50%"
                    >
                      <a-step title="Anamnesis" />
                      <a-step title="Penunjang" />
                    </a-steps>
                  </div>
                  <a-divider />
                  <div v-if="activeKey === 1">
                    <a-form
                      :form="form"
                      @submit.prevent="handleSubmitNurseCheckUp"
                    >
                      <a-form-item>
                        <div class="h6 font-weight-bold">Perawat</div>
                        <a-input
                          v-model="nurseName"
                          placeholder="Nama Perawat"
                          disabled
                        />
                      </a-form-item>
                      <a-form-item>
                        <div class="h6 font-weight-bold">
                          Keluhan Utama<span class="text-danger">*</span>
                        </div>
                        <div
                          v-for="(keluhan, index) in keluhan_utama"
                          :key="index"
                        >
                          <div class="d-flex align-items-center">
                            <a-select
                              show-search
                              @search="cariKeluhanUtama"
                              :filter-option="false"
                              :not-found-content="null"
                              v-model="keluhan.keterangan"
                              :default-active-first-option="false"
                              :show-arrow="false"
                              style="min-width: 15rem; max-width: 70vw"
                              @change="selectKeluhanUtama(index, $event)"
                            >
                              <a-select-option
                                v-for="(item, index) in list_keluhan_utama"
                                :key="index"
                                :value="item.keterangan"
                              >
                                {{ item.keterangan }}
                              </a-select-option>
                            </a-select>
                            <a
                              @click="removeKeluhanUtama(index)"
                              class="h3 ml-3"
                              >x</a
                            >
                          </div>
                        </div>
                        <a
                          @click="addKeluhanUtama"
                          class="mt-5 h6"
                          style="color: #428a94"
                          >+ Tambah Keluhan Utama</a
                        >
                      </a-form-item>
                      <a-form-item>
                        <div class="h6 font-weight-bold">Riwayat Penyakit</div>
                        <div
                          v-if="
                            riwayat_penyakit.length > 0 ||
                            riwayat_penyakit_sebelumnya.length > 0
                          "
                          class="d-flex justify-content-between align-items-center mb-2"
                          style="height: 30px"
                        >
                          <div class="col-xl-5">
                            <div style="height: 25px">Kategori</div>
                          </div>
                          <div class="col-xl-6">
                            <div style="height: 25px">Riwayat Penyakit</div>
                          </div>
                          <div class="col-xl-1"></div>
                        </div>
                        <div
                          v-for="(
                            riwayat, index
                          ) in riwayat_penyakit_sebelumnya"
                          :key="index"
                        >
                          <div
                            class="d-flex justify-content-between align-items-center"
                            style="height: 30px"
                          >
                            <div class="col-xl-5">
                              <div
                                style="height: 25px"
                                v-if="riwayat.type === 'personal'"
                                class="ml-2"
                              >
                                Riwayat Penyakit Pribadi
                              </div>
                              <div
                                style="height: 25px"
                                v-if="riwayat.type === 'family'"
                                class="ml-2"
                              >
                                Riwayat Penyakit Keluarga
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div style="height: 25px" class="ml-2">
                                {{ riwayat.display }}
                              </div>
                            </div>
                            <div class="col-xl-1">
                              <a
                                @click="
                                  confirmDeleteIllnessHistory(riwayat, index, 1)
                                "
                                class="h3"
                                >x</a
                              >
                            </div>
                          </div>
                        </div>
                        <hr
                          v-if="
                            this.riwayat_penyakit_sebelumnya.length > 0 &&
                            this.riwayat_penyakit.length > 0
                          "
                        />
                        <div
                          v-for="(riwayat, index) in riwayat_penyakit"
                          :key="index"
                        >
                          <div class="d-flex align-items-center">
                            <div class="col-xl-5">
                              <a-select
                                v-model="riwayat.type"
                                style="margin-right: 8px; padding-bottom: 8px"
                              >
                                <a-select-option value="personal"
                                  >Riwayat Penyakit Pribadi</a-select-option
                                >
                                <a-select-option value="family"
                                  >Riwayat Penyakit Keluarga</a-select-option
                                >
                              </a-select>
                            </div>
                            <div class="col-xl-6">
                              <a-select
                                show-search
                                @search="
                                  (query) => cariRiwayatPenyakit(query, index)
                                "
                                :filter-option="false"
                                :not-found-content="null"
                                v-model="riwayat.display"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                style="min-width: 15rem; max-width: 70vw"
                                @change="selectRiwayatPenyakit(index, $event)"
                              >
                                <a-select-option
                                  v-for="(item, index) in list_riwayat_penyakit"
                                  :key="index"
                                  :value="item.display"
                                >
                                  {{ item.display }}
                                </a-select-option>
                              </a-select>
                            </div>
                            <div class="col-xl-1">
                              <a
                                @click="
                                  confirmDeleteIllnessHistory(riwayat, index, 2)
                                "
                                class="h3"
                                >x</a
                              >
                            </div>
                          </div>
                        </div>
                        <br
                          v-if="
                            riwayat_penyakit.length > 0 ||
                            riwayat_penyakit_sebelumnya.length > 0
                          "
                        />
                        <a
                          @click="addRiwayatPenyakit"
                          class="mt-5 h6"
                          style="color: #428a94"
                          >+ Tambah Riwayat Penyakit</a
                        >
                      </a-form-item>
                      <div class="h6 font-weight-bold">Riwayat Alergi</div>
                      <div class="mb-3">
                        Riwayat alergi yang pernah dialami oleh pasien
                      </div>
                      <div
                        v-if="
                          alergiTambahan.length > 0 ||
                          alergiSebelumnya.length > 0
                        "
                        class="d-flex justify-content-between align-items-center"
                        style="height: 30px"
                      >
                        <div class="col-xl-5">
                          <div style="height: 25px">Jenis Alergi</div>
                        </div>
                        <div class="col-xl-6">
                          <div style="height: 25px">Nama Alergen</div>
                        </div>
                        <div class="col-xl-1"></div>
                      </div>
                      <div
                        v-for="(alergi, index) in alergiSebelumnya"
                        :key="index"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                          style="height: 30px"
                        >
                          <div class="col-xl-5">
                            <div
                              style="height: 25px"
                              v-if="alergi.allergy_type_id === 1"
                              class="ml-2"
                            >
                              Medication
                            </div>
                            <div
                              style="height: 25px"
                              v-if="alergi.allergy_type_id === 2"
                              class="ml-2"
                            >
                              Food
                            </div>
                            <div
                              style="height: 25px"
                              v-if="alergi.allergy_type_id === 3"
                              class="ml-2"
                            >
                              Environment
                            </div>
                            <div
                              style="height: 25px"
                              v-if="alergi.allergy_type_id === 4"
                              class="ml-2"
                            >
                              Biologic
                            </div>
                          </div>
                          <div class="col-xl-6">
                            <div style="height: 25px" class="ml-2">
                              {{ alergi.display }}
                            </div>
                          </div>
                          <div class="col-xl-1">
                            <a
                              @click="confirmDeleteAllergy(alergi, index, 1)"
                              class="h3"
                              >x</a
                            >
                          </div>
                        </div>
                      </div>
                      <hr
                        v-if="
                          this.alergiSebelumnya.length > 0 &&
                          this.alergiTambahan.length > 0
                        "
                      />
                      <a-form-item
                        v-for="(input, index) in alergiTambahan"
                        :key="index"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                          style="height: 30px"
                        >
                          <div class="col-xl-5">
                            <a-select v-model="input.type">
                              <a-select-option value="1"
                                >Medication</a-select-option
                              >
                              <a-select-option value="2">Food</a-select-option>
                              <a-select-option value="3"
                                >Environment</a-select-option
                              >
                              <a-select-option value="4"
                                >Biologic</a-select-option
                              >
                            </a-select>
                          </div>
                          <div class="col-xl-6">
                            <a-select
                              show-search
                              @search="cariAlergi"
                              :filter-option="false"
                              :not-found-content="null"
                              v-model="input.display"
                              :default-active-first-option="false"
                              :show-arrow="false"
                              style="min-width: 15rem; max-width: 70vw"
                              @change="selectAlergi(index, $event)"
                            >
                              <a-select-option
                                v-for="(item, index) in list_alergi"
                                :key="index"
                                :value="item.display"
                              >
                                {{ item.display }}
                              </a-select-option>
                            </a-select>
                          </div>
                          <div class="col-xl-1">
                            <!-- <a @click="removeInput(index)" class="h4">X</a> -->
                            <a
                              @click="confirmDeleteAllergy(input, index, 2)"
                              class="h3"
                              >x</a
                            >
                          </div>
                        </div>
                      </a-form-item>
                      <br
                        v-if="
                          alergiSebelumnya.length > 0 ||
                          alergiTambahan.length > 0
                        "
                      />
                      <a
                        @click="addInput"
                        class="mt-2 h6"
                        style="color: #428a94"
                        >+ Tambah Riwayat Alergi</a
                      >

                      <a-form-item>
                        <div class="h6 font-weight-bold mt-3">
                          Riwayat Pengobatan
                        </div>
                        <div
                          v-if="
                            riwayat_pengobatan.length > 0 ||
                            riwayat_pengobatan_sebelumnya.length > 0
                          "
                          class="d-flex justify-content-between align-items-center mb-2"
                          style="height: 30px"
                        >
                          <div class="col-xl-4">
                            <div style="height: 25px">Nama Obat</div>
                          </div>
                          <div class="col-xl-4">
                            <div style="height: 25px">Dosis dan Frekuensi</div>
                          </div>
                          <div class="col-xl-3">
                            <div style="height: 25px">
                              Status Pemakaian Obat
                            </div>
                          </div>
                          <div class="col-xl-1"></div>
                        </div>
                        <div
                          v-for="(
                            riwayatObat, index
                          ) in riwayat_pengobatan_sebelumnya"
                          :key="index"
                        >
                          <div class="d-flex align-items-start">
                            <div class="col-xl-4">
                              <div style="line-height: normal" class="ml-2">
                                {{ riwayatObat.name }}
                              </div>
                            </div>
                            <div class="col-xl-4">
                              <div class="ml-2">{{ riwayatObat.dosage }}</div>
                            </div>
                            <div class="col-xl-3">
                              <div class="ml-2">
                                {{
                                  medicineStatus.find(
                                    (item) => item.value === riwayatObat.status,
                                  ).label
                                }}
                              </div>
                            </div>
                            <div class="col-xl-1">
                              <a
                                @click="
                                  confirmDeleteMedicineStatement(
                                    riwayatObat,
                                    index,
                                    1,
                                  )
                                "
                                class="h3"
                                >x</a
                              >
                            </div>
                          </div>
                        </div>
                        <hr
                          v-if="
                            this.riwayat_pengobatan_sebelumnya.length > 0 &&
                            this.riwayat_pengobatan.length > 0
                          "
                        />
                        <div
                          v-for="(riwayatObat, index) in riwayat_pengobatan"
                          :key="index"
                        >
                          <div class="d-flex align-items-center">
                            <div class="col-xl-4">
                              <a-select
                                show-search
                                @search="searchMedicine"
                                :filter-option="false"
                                :not-found-content="null"
                                v-model="riwayatObat.name"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                style="min-width: 15rem; max-width: 70vw"
                                @change="selectMedicine(index, $event)"
                              >
                                <a-select-option
                                  v-for="item in list_obat"
                                  :key="item.id"
                                  :value="item.name"
                                >
                                  {{ item.name }}
                                </a-select-option>
                              </a-select>
                            </div>
                            <div class="col-xl-4">
                              <a-input
                                v-model="riwayatObat.dosage"
                                placeholder="(Contoh: 3 kali sehari 1 tab)"
                                style="margin-right: 8px; padding-bottom: 8px"
                              />
                            </div>
                            <div class="col-xl-3">
                              <a-select
                                v-model="riwayatObat.status"
                                style="margin-right: 8px; padding-bottom: 8px"
                              >
                                <a-select-option
                                  v-for="(item, index) in medicineStatus"
                                  :key="index"
                                  :value="item.value"
                                >
                                  {{ item.label }}
                                </a-select-option>
                              </a-select>
                            </div>
                            <div class="col-xl-1">
                              <a
                                @click="
                                  confirmDeleteMedicineStatement(
                                    riwayatObat,
                                    index,
                                    2,
                                  )
                                "
                                class="h3"
                                >x</a
                              >
                            </div>
                          </div>
                        </div>
                        <br
                          v-if="
                            riwayat_pengobatan.length > 0 ||
                            riwayat_pengobatan_sebelumnya.length > 0
                          "
                        />
                        <a
                          @click="addRiwayatPengobatan"
                          class="mt-5 h6"
                          style="color: #428a94"
                          >+ Tambah Riwayat Pengobatan</a
                        >
                      </a-form-item>

                      <a-divider />

                      <table>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Suhu
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'suhu',
                                  {
                                    rules: [
                                      //   {
                                      //     required: true,
                                      //     message: 'Suhu diperlukan',
                                      //   },
                                      {
                                        type: 'number',
                                        min: 10,
                                        max: 50,
                                        message:
                                          'Suhu harus berada diantara 10-50',
                                      },
                                    ],
                                    initialValue: null,
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td
                            style="
                              padding-left: 12px;
                              padding-bottom: 25px;
                              padding-bottom: 25px;
                            "
                          >
                            °C
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Tekanan Darah Sistolik<span class="text-danger"
                              >*</span
                            >
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'tekanan_darah_sistolik',
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          'Tekanan darah sistolik diperlukan',
                                      },
                                      {
                                        type: 'number',
                                        min: 60,
                                        max: 300,
                                        message:
                                          'Tekanan darah sistolik harus berada diantara 60 dan 300',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            mmHg
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Tekanan Darah Diastolik<span class="text-danger"
                              >*</span
                            >
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'tekanan_darah_diastolik',
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          'Tekanan darah diastolik diperlukan',
                                      },
                                      {
                                        type: 'number',
                                        min: 30,
                                        max: 200,
                                        message:
                                          'Tekanan darah diastolik harus berada diantara 30 dan 200',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            mmHg
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Saturasi Oksigen
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'saturasi_oksigen',
                                  {
                                    rules: [
                                      //   {
                                      //     required: true,
                                      //     message: 'Saturasi oksigen diperlukan',
                                      //   },
                                      {
                                        type: 'number',
                                        min: 1,
                                        max: 100,
                                        message:
                                          'Saturasi oksigen harus berada diantara 1 dan 100',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            %
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Frekuensi Nafas
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'frekuensi_nafas',
                                  {
                                    rules: [
                                      //   {
                                      //     required: true,
                                      //     message: 'Frekuensi nafas diperlukan',
                                      //   },
                                      {
                                        type: 'number',
                                        min: 1,
                                        max: 100,
                                        message:
                                          'Frekuensi nafas harus berada diantara 1 dan 100',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            kali/menit
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Nadi
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'nadi',
                                  {
                                    rules: [
                                      //   {
                                      //     required: true,
                                      //     message: 'Nadi diperlukan',
                                      //   },
                                      {
                                        type: 'number',
                                        min: 1,
                                        max: 100,
                                        message:
                                          'Nadi harus berada diantara 1 dan 100',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            kali/menit
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Tinggi Badan
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'tinggi_badan',
                                  {
                                    rules: [
                                      {
                                        type: 'number',
                                        min: 1,
                                        max: 250,
                                        message:
                                          'Tinggi badan harus berada diantara 1 dan 250',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            cm
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              height: 40px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Berat Badan
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'berat_badan',
                                  {
                                    rules: [
                                      {
                                        type: 'number',
                                        min: 0.1,
                                        max: 650,
                                        message:
                                          'Berat badan harus berada diantara 0,1 dan 650',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            kg
                          </td>
                        </tr>
                        <tr>
                          <td
                            style="
                              width: 180px;
                              padding-right: 15px;
                              padding-bottom: 25px;
                            "
                          >
                            Lingkar Perut
                          </td>
                          <td style="padding-left: 15px; width: 130px">
                            <a-form-item>
                              <a-input-number
                                v-decorator="[
                                  'lingkar_perut',
                                  {
                                    rules: [
                                      {
                                        type: 'number',
                                        min: 1,
                                        max: 200,
                                        message:
                                          'Lingkar perut harus berada diantara 1 dan 200',
                                      },
                                    ],
                                  },
                                ]"
                                style="width: 100%"
                              />
                            </a-form-item>
                          </td>
                          <td style="padding-left: 12px; padding-bottom: 25px">
                            cm
                          </td>
                        </tr>
                      </table>

                      <a-divider />

                      <div class="d-flex justify-content-end">
                        <!-- <a-button type="primary" @click="handleSubmitNurseCheckUp" :loading="checkupLoading" style="border-radius: 26px; height: 40px;">
                                                        <img src="resources/images/save-icon.svg" alt="submit" style="width: 20px; height: 20px;"/>
                                                        <span class="ml-2">Simpan Anamnesis</span>
                                                </a-button> -->
                        <a-button
                          type="primary"
                          @click="openAnamnesisModal"
                          :loading="checkupLoading"
                          style="border-radius: 26px; height: 40px"
                        >
                          <img
                            src="resources/images/save-icon.svg"
                            alt="submit"
                            style="width: 20px; height: 20px"
                          />
                          <span class="ml-2">Simpan Anamnesis</span>
                        </a-button>
                      </div>
                      <customModal
                        :visible="showModal"
                        @close="showModal = false"
                        v-if="showModal === true"
                      >
                        <div>
                          <div
                            class="mb-4 mt-2 h4 font-weight-bold text-color-green"
                          >
                            Ringkasan Pemeriksaan Perawat
                          </div>
                          <div class="card">
                            <div class="card-body background-blue">
                              <div class="">
                                <div class="row">
                                  <div class="col-xl-4">
                                    <table>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          Nama
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{
                                            this.patientVisitData.Patient.name
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          Tanggal Lahir
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{ this.patientVisitData.birth_date }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          Usia
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{ this.patientVisitData.age }}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div class="col-xl-4">
                                    <table>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          Jenis Kelamin
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{ this.patientVisitData.gender }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          Cara Pembayaran
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{
                                            this.patientVisitData.Patient
                                              .payment_method
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          Dokter
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{
                                            this.patientVisitData.Doctor.User
                                              .name
                                          }}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div class="col-xl-4">
                                    <table>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          No. RM
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{
                                            this.patientVisitData.Patient
                                              .medical_record_no
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style="
                                            width: 120px;
                                            height: 32px;
                                            padding-right: 8px;
                                          "
                                        >
                                          Status
                                        </td>
                                        <td>:</td>
                                        <td style="padding-left: 8px">
                                          {{ this.patientVisitData.status }}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="ml-3 mr-3">
                            <p class="h5 font-weight-bold">Anamnesis</p>
                            <div class="font-weight-bold text-color-green">
                              Nama Perawat
                            </div>
                            <div>{{ nurseName }}</div>
                            <br />
                            <div class="font-weight-bold text-color-green">
                              Keluhan Utama
                            </div>
                            <div>
                              <div
                                v-for="(keluhan, index) in this.keluhan_utama"
                                :key="index"
                              >
                                <div>• {{ keluhan.keterangan }}</div>
                              </div>
                            </div>
                            <br />
                            <div class="font-weight-bold text-color-green">
                              Riwayat Penyakit
                            </div>
                            <div>
                              <table class="table">
                                <thead>
                                  <th
                                    style="
                                      width: 200px;
                                      height: 32px;
                                      padding-right: 15px;
                                    "
                                  >
                                    Kategori
                                  </th>
                                  <th>Nama Penyakit</th>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(riwayat, index) in this
                                      .riwayat_penyakit_sebelumnya"
                                    :key="index"
                                  >
                                    <td v-if="riwayat.type === 'personal'">
                                      Riwayat Penyakit Pribadi
                                    </td>
                                    <td v-if="riwayat.type === 'family'">
                                      Riwayat Penyakit Keluarga
                                    </td>
                                    <td>{{ riwayat.display }}</td>
                                  </tr>
                                  <tr
                                    v-for="(riwayat, index) in this
                                      .riwayat_penyakit"
                                    :key="index"
                                  >
                                    <td v-if="riwayat.type === 'personal'">
                                      Riwayat Penyakit Pribadi
                                    </td>
                                    <td v-if="riwayat.type === 'family'">
                                      Riwayat Penyakit Keluarga
                                    </td>
                                    <td>{{ riwayat.display }}</td>
                                  </tr>
                                  <tr
                                    v-if="
                                      this.riwayat_penyakit.length === 0 &&
                                      this.riwayat_penyakit_sebelumnya
                                        .length === 0
                                    "
                                  >
                                    <td>Tidak ada data</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <br />
                            <div class="font-weight-bold text-color-green">
                              Riwayat Alergi
                            </div>
                            <div>
                              <table class="table">
                                <thead>
                                  <th
                                    style="
                                      width: 160px;
                                      height: 32px;
                                      padding-right: 15px;
                                    "
                                  >
                                    Jenis Alergi
                                  </th>
                                  <th>Nama Alergen</th>
                                </thead>
                                <tbody>
                                  <div
                                    v-if="
                                      this.alergiTambahan.length === 0 &&
                                      this.alergiSebelumnya.length === 0
                                    "
                                  >
                                    <tr
                                      v-for="prevAllergy in this
                                        .alergiSebelumnya"
                                      :key="prevAllergy.id"
                                    >
                                      <td
                                        v-if="prevAllergy.allergy_type_id === 1"
                                      >
                                        Medication
                                      </td>
                                      <td
                                        v-if="prevAllergy.allergy_type_id === 2"
                                      >
                                        Food
                                      </td>
                                      <td
                                        v-if="prevAllergy.allergy_type_id === 3"
                                      >
                                        Environment
                                      </td>
                                      <td
                                        v-if="prevAllergy.allergy_type_id === 4"
                                      >
                                        Biologic
                                      </td>
                                      <td>{{ prevAllergy.display }}</td>
                                    </tr>
                                    <tr
                                      v-for="allergy in this.alergiTambahan"
                                      :key="allergy.id"
                                    >
                                      <td v-if="allergy.type === '1'">
                                        Medication
                                      </td>
                                      <td v-if="allergy.type === '2'">Food</td>
                                      <td v-if="allergy.type === '3'">
                                        Environment
                                      </td>
                                      <td v-if="allergy.type === '4'">
                                        Biologic
                                      </td>
                                      <td>{{ allergy.display }}</td>
                                    </tr>
                                  </div>

                                  <tr v-else>
                                    <td>Tidak ada data</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div class="font-weight-bold text-color-green">
                              Riwayat Pengobatan
                            </div>
                            <div>
                              <table class="table">
                                <thead>
                                  <th
                                    style="
                                      max-width: 60vw;
                                      height: 32px;
                                      padding-right: 15px;
                                    "
                                  >
                                    Nama Obat
                                  </th>
                                  <th>Dosis dan Frekuensi</th>
                                  <th>Status Pemakaian Obat</th>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(riwayatObat, index) in this
                                      .riwayat_pengobatan_sebelumnya"
                                    :key="index"
                                  >
                                    <td>{{ riwayatObat.name }}</td>
                                    <td>{{ riwayatObat.dosage }}</td>
                                    <td>
                                      {{
                                        medicineStatus.find(
                                          (item) =>
                                            item.value === riwayatObat.status,
                                        )?.label || "-"
                                      }}
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(riwayatObat, index) in this
                                      .riwayat_pengobatan"
                                    :key="index"
                                  >
                                    <td>{{ riwayatObat.name }}</td>
                                    <td>{{ riwayatObat.dosage }}</td>
                                    <td>
                                      {{
                                        medicineStatus.find(
                                          (item) =>
                                            item.value === riwayatObat.status,
                                        )?.label || "-"
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <a-divider />
                            <table>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Suhu
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                >
                                  {{ this.suhu }} °C
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Tekanan Darah Sistolik
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                >
                                  {{ this.tekanan_darah_sistolik }} mmHg
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Tekanan Darah Diastolik
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                >
                                  {{ this.tekanan_darah_diastolik }} mmHg
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Saturasi Oksigen
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                >
                                  {{ this.saturasi_oksigen }} %
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Frekuensi Nafas
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                >
                                  {{ this.frekuensi_nafas }} kali/menit
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Nadi
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                >
                                  {{ this.nadi }} kali/menit
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Tinggi Badan
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                  v-if="this.tinggi_badan"
                                >
                                  {{ this.tinggi_badan }} cm
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                  v-else
                                >
                                  -
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="
                                    width: 180px;
                                    height: 21px;
                                    padding-bottom: 16px;
                                  "
                                  class="text-color-green font-weight-bold"
                                >
                                  Berat Badan
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                  v-if="this.berat_badan"
                                >
                                  {{ this.berat_badan }} kg
                                </td>
                                <td
                                  style="
                                    padding-left: 24px;
                                    width: 130px;
                                    padding-bottom: 16px;
                                  "
                                  v-else
                                >
                                  -
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style="width: 180px; height: 21px"
                                  class="text-color-green font-weight-bold"
                                >
                                  Lingkar Perut
                                </td>
                                <td
                                  style="padding-left: 24px; width: 130px"
                                  v-if="this.lingkar_perut"
                                >
                                  {{ this.lingkar_perut }} cm
                                </td>
                                <td
                                  style="padding-left: 24px; width: 130px"
                                  v-else
                                >
                                  -
                                </td>
                              </tr>
                            </table>
                            <a-divider />
                            <div>
                              <a-checkbox
                                @change="onCheckboxChange"
                                :defaultChecked="this.verifiedCheckbox"
                              >
                                Saya telah memverifikasi bahwa semua data medis
                                pasien yang tercantum di atas adalah benar dan
                                lengkap sesuai dengan hasil pemeriksaan yang
                                telah dilakukan.
                              </a-checkbox>
                              <div class="mt-2">
                                <a-button
                                  type="primary"
                                  @click="handleSubmitNurseCheckUp"
                                  :loading="checkupLoading"
                                  :disabled="!this.verifiedCheckbox"
                                  style="border-radius: 26px; height: 40px"
                                >
                                  <img
                                    src="resources/images/save-icon.svg"
                                    alt="submit"
                                    style="width: 20px; height: 20px"
                                  />
                                  <span class="ml-2">Simpan Anamnesis</span>
                                </a-button>
                                <a
                                  @click="showModal = false"
                                  class="kit__utils__link font-size-16 ml-3"
                                  >Batal</a
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </customModal>
                    </a-form>
                  </div>
                  <div v-if="activeKey === 2">
                    <div>
                      <div class="mb-4 h6 font-weight-bold">Penunjang</div>
                      <div>
                        <a-upload-dragger
                          v-model="fileList"
                          :multiple="true"
                          :before-upload="beforeUpload"
                          accept=".png,.jpg,.jpeg,.pdf"
                          list-type="picture"
                          :showUploadList="false"
                        >
                          <img
                            src="resources/images/cloud-upload-icon.svg"
                            alt="upload"
                            style="width: 48px; height: 48px"
                          />
                          <p class="ant-upload-text">
                            Drag dan drop dokumen atau klik disini
                          </p>
                          <p class="ant-upload-hint">(PNG/JPG/PDF)</p>
                        </a-upload-dragger>

                        <ul class="file-list">
                          <li v-for="(file, index) in fileList" :key="index">
                            <div class="d-flex align-items-center list-border">
                              <img
                                :src="
                                  file.type === 'application/pdf'
                                    ? 'resources/images/pdf-icon.svg'
                                    : file.thumbUrl
                                "
                                alt="thumbnail"
                                class="mr-2"
                                style="width: 48px; height: 48px"
                              />
                              <div v-if="editIndex === index">
                                <input
                                  v-model="editName"
                                  @blur="finishEdit(index)"
                                  @keyup.enter="finishEdit(index)"
                                  type="text"
                                  autofocus
                                  class="edit-file-input"
                                />
                                <a @click="finishEdit(index)" class="ml-2"
                                  >Simpan</a
                                >
                              </div>
                              <div
                                v-else
                                class="d-flex justify-content-between"
                                style="width: 1000px"
                              >
                                <div>
                                  {{ file.name }}
                                  <a @click="startEdit(index, file.name)">
                                    <img
                                      src="resources/images/edit-icon.svg"
                                      alt="edit"
                                      class="ml-2"
                                      style="width: 16px; height: 16px"
                                    />
                                  </a>
                                </div>
                                <a @click="removeFile(index)">
                                  <img
                                    src="resources/images/trash-icon.svg"
                                    alt="delete"
                                    style="width: 24px; height: 24px"
                                  />
                                </a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-end mt-3"
                      >
                        <router-link
                          to="/pemeriksaan/perawat"
                          class="kit__utils__link font-size-16 mr-3"
                          >Unggah Penunjang Nanti</router-link
                        >
                        <a-button
                          type="primary"
                          @click="openModal"
                          style="border-radius: 26px; height: 40px"
                        >
                          <div class="d-flex align-items-center">
                            <a-spin v-if="isLoading" />
                            <img
                              v-if="!isLoading"
                              src="resources/images/save-icon.svg"
                              alt="submit"
                              style="width: 20px; height: 20px"
                            />
                            <span class="ml-2">Simpan Penunjang</span>
                          </div>
                        </a-button>
                      </div>
                      <a-modal
                        title="Yakin isi formulir sudah sesuai?"
                        v-model="isModalVisible"
                        @ok="uploadFiles"
                        @cancel="cancelModal"
                        ok-text="Ya"
                        cancel-text="Kembali"
                        :confirm-loading="dokumenLoading"
                      >
                        <p>
                          Mohon cek kembali dokumen penunjang sebelum menyimpan
                          formulir.
                        </p>
                      </a-modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPatientVisit,
  nurseCheckup,
  uploadDokumenPenunjang,
} from "@/services/axios/api/kunjungan"
import { getMedications } from "@/services/axios/api/pengobatan"
import {
  searchKeluhanUtama,
  searchRiwayatPribadi,
  searchRiwayatKeluarga,
  searchAlergi,
} from "@/services/axios/api/snomedCT"
import {
  getAllergy,
  deleteAllergy,
  getIllnessHistory,
  deleteIllnessHistory,
  getMedicationStatement,
  deleteMedicationStatement,
} from "@/services/axios/api/healthRecord"
import { getById } from "@/services/axios/api/pasien"
import customModal from "./customModal.vue"
import { Modal } from "ant-design-vue"

export default {
  components: {
    customModal,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      activeKey: 1,
      visit_id: "",
      patientVisitData: [],
      nurseName: "",
      fileList: [],
      editIndex: null,
      editName: "",
      isLoading: false,
      dokumenLoading: false,
      isModalVisible: false,
      checkupLoading: false,
      showModal: false,
      keluhan_utama: [],
      riwayat_penyakit_sebelumnya: [],
      riwayat_penyakit: [],
      alergiSebelumnya: [],
      alergiTambahan: [],
      riwayat_pengobatan_sebelumnya: [],
      riwayat_pengobatan: [],
      list_keluhan_utama: [],
      list_riwayat_penyakit: [],
      list_alergi: [],
      list_obat: [],
      medicineStatus: [
        { label: "Active", value: "active" },
        { label: "Completed", value: "completed" },
        { label: "Entered in Error", value: "entered-in-error" },
        { label: "Intended", value: "intended" },
        { label: "Stopped", value: "stopped" },
        { label: "Draft", value: "draft" },
        { label: "On Hold", value: "on-hold" },
        { label: "Unknown", value: "unknown" },
        { label: "Not Taken", value: "not-taken" },
      ],
      suhu: null,
      tekanan_darah_sistolik: null,
      tekanan_darah_diastolik: null,
      saturasi_oksigen: null,
      frekuensi_nafas: null,
      nadi: null,
      tinggi_badan: null,
      berat_badan: null,
      lingkar_perut: null,
      verifiedCheckbox: false,
    }
  },
  async created() {
    await this.getName()
    this.visit_id = this.$route.params.id
    await this.getPatientVisitData()
    if (this.patientVisitData.status !== "Menunggu Perawat") {
      this.$notification.warning({
        message: "Peringatan",
        description: "Data pemeriksaan perawat telah terisi!",
        duration: 5,
      })
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    },
    addKeluhanUtama() {
      this.keluhan_utama.push({
        code: null,
        display: "",
        system: "",
        keterangan: "",
      })
    },
    removeKeluhanUtama(index) {
      this.keluhan_utama.splice(index, 1)
    },
    addRiwayatPenyakit() {
      this.riwayat_penyakit.push({
        type: "",
        code: null,
        display: "",
        system: "",
      })
    },
    removeRiwayatPenyakit(index) {
      this.riwayat_penyakit.splice(index, 1)
      this.$notification.success({
        message: "Berhasil",
        description: "Data riwayat penyakit berhasil dihapus",
        duration: 5,
      })
    },
    addRiwayatPengobatan() {
      this.riwayat_pengobatan.push({
        kfa: "",
        name: "",
        dosage: "",
        status: "",
      })
    },
    removeRiwayatPengobatan(index) {
      this.riwayat_pengobatan.splice(index, 1)
      this.$notification.success({
        message: "Berhasil",
        description: "Data riwayat pengobatan berhasil dihapus",
        duration: 5,
      })
    },
    addInput() {
      this.alergiTambahan.push({
        type: null,
        code: null,
        display: "",
        system: "",
      })
    },
    removeInput(index) {
      this.alergiTambahan.splice(index, 1)
      this.$notification.success({
        message: "Berhasil",
        description: "Data alergi berhasil dihapus",
        duration: 5,
      })
    },
    selectKeluhanUtama(index, keluhanUtama) {
      const selectedKeluhanUtama = this.list_keluhan_utama.find(
        (item) => item.keterangan === keluhanUtama,
      )
      this.keluhan_utama[index] = {
        code: selectedKeluhanUtama.code,
        display: selectedKeluhanUtama.display,
        system: selectedKeluhanUtama.system,
        keterangan: selectedKeluhanUtama.keterangan,
      }
      this.list_keluhan_utama = []
    },
    selectRiwayatPenyakit(index, riwayatPenyakit) {
      const selectedRiwayatPenyakit = this.list_riwayat_penyakit.find(
        (item) => item.display === riwayatPenyakit,
      )
      const type = this.riwayat_penyakit[index].type
      this.riwayat_penyakit[index] = {
        type: type,
        code: selectedRiwayatPenyakit.code,
        display: selectedRiwayatPenyakit.display,
        system: selectedRiwayatPenyakit.system,
      }
      this.list_riwayat_penyakit = []
    },
    selectAlergi(index, alergi) {
      const selectedAlergi = this.list_alergi.find(
        (item) => item.display === alergi,
      )
      const type = this.alergiTambahan[index].type
      this.alergiTambahan[index] = {
        type: type,
        code: selectedAlergi.code,
        display: selectedAlergi.display,
        system: selectedAlergi.system,
      }
      this.list_alergi = []
    },
    selectMedicine(index, medicine) {
      const selectedMedicine = this.list_obat.find(
        (item) => item.name === medicine,
      )
      this.riwayat_pengobatan[index].kfa = selectedMedicine.id
      this.riwayat_pengobatan[index].name = selectedMedicine.name
      this.list_obat = []
    },
    getName() {
      const name = window.localStorage.getItem("name")
      this.nurseName = name
    },
    calculateAge(birthdate) {
      const today = new Date()
      const birthDate = new Date(birthdate)

      const years = today.getFullYear() - birthDate.getFullYear()
      const months = today.getMonth() - birthDate.getMonth()
      const days = today.getDate() - birthDate.getDate()

      let ageYears = years
      let ageMonths = months
      let ageDays = days

      if (ageDays < 0) {
        const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0)
        ageDays += prevMonth.getDate()
        ageMonths -= 1
      }

      if (ageMonths < 0) {
        ageMonths += 12
        ageYears -= 1
      }

      return `${ageYears} tahun, ${ageMonths} bulan, ${ageDays} hari`
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" }
      const date = new Date(dateString)
      return date.toLocaleString("id-ID", options)
    },
    async getPatientVisitData() {
      const response = await getPatientVisit(this.visit_id)
      this.patientVisitData = response.data.visit

      this.patientVisitData = {
        ...this.patientVisitData,
        age: this.calculateAge(this.patientVisitData.Patient.birth_date),
        birth_date: this.formatDate(this.patientVisitData.Patient.birth_date),
        gender:
          this.patientVisitData.Patient.gender === "Male"
            ? "Laki-laki"
            : "Perempuan",
        payment_method:
          this.patientVisitData.Patient.payment_method === "JKN"
            ? "JKN (BPJS Kesehatan)"
            : this.patientVisitData.Patient.payment_method,
      }
      console.log(this.patientVisitData, "data kunjungan pasien")
      await this.getPatientAllergy(this.patientVisitData.patient_id)
      await this.getPatientIllnessHistory(this.patientVisitData.patient_id)
      await this.getPatientMedicationStatement(this.patientVisitData.patient_id)
      // await this.getPreviousMedication(this.patientVisitData.patient_id)
    },
    async handleSubmitNurseCheckUp(e) {
      e.preventDefault()
      this.checkupLoading = true
      if (this.patientVisitData.status !== "Menunggu Perawat") {
        this.$notification.error({
          message: "Gagal Menyimpan",
          description: "Data pemeriksaan perawat telah terisi sebelumnya!",
          duration: 5,
        })
        this.checkupLoading = false
        return
      }
      this.isLoading = true
      this.form.validateFields(async (err, values) => {
        if (!err) {
          values.tambahan_riwayat_alergi = this.alergiTambahan
          values.keluhan_utama = this.keluhan_utama
          values.tambahan_riwayat_penyakit = this.riwayat_penyakit
          values.tambahan_riwayat_pengobatan = this.riwayat_pengobatan
          values.visit_id = this.visit_id
          // console.log(values, 'values yg diinputnya')
          nurseCheckup(values)
            .then(async (response) => {
              if (response.status === 200 || response.status === 201) {
                this.$notification.success({
                  message: "Berhasil",
                  description: "Data pemeriksaan perawat berhasil disimpan",
                  duration: 5,
                })
                this.changeTab(2)
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                // this.checkupLoading = false
                await this.getPatientVisitData()
              }
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log(err)
        }
      })
      this.isLoading = false
      this.checkupLoading = false
    },

    beforeUpload(file) {
      file.thumbUrl = URL.createObjectURL(file)
      this.fileList.push(file)
      return false
    },

    startEdit(index, name) {
      this.editIndex = index
      this.editName = name
    },

    finishEdit(index) {
      if (this.editName) {
        const newFile = new File([this.fileList[index]], this.editName, {
          type: this.fileList[index].type,
        })
        this.fileList[index] = newFile
        this.fileList[index].thumbUrl = URL.createObjectURL(newFile)
      }
      this.editIndex = null
      this.editName = ""
    },

    removeFile(index) {
      this.fileList.splice(index, 1)
    },

    uploadFiles() {
      this.isLoading = true
      this.dokumenLoading = true
      const formData = new FormData()
      console.log(this.fileList, "file list")
      this.fileList.forEach((file) => {
        formData.append("documents", file)
      })
      formData.append("visit_id", this.visit_id)

      console.log(formData, "form data")

      uploadDokumenPenunjang(formData)
        .then((response) => {
          console.log(response, "response")
          if (response.status === 200 || response.status === 201) {
            this.$notification.success({
              message: "Berhasil",
              description: "Dokumen penunjang berhasil diunggah",
              duration: 5,
            })
            this.$router.push("/pemeriksaan/perawat")
          }
        })
        .catch((err) => {
          console.log(err)
        })
      this.isLoading = false
      this.dokumenLoading = false
    },
    openModal() {
      if (this.fileList.length === 0) {
        this.$notification.error({
          message: "Tidak ada file yang akan di upload!",
          description: "Silahkan pilih file terlebih dahulu",
          duration: 5,
        })
        return
      }
      this.isModalVisible = true
    },
    openAnamnesisModal() {
      this.verifiedCheckbox = false
      if (
        this.keluhan_utama.length === 0 ||
        this.keluhan_utama.some(
          (item) => item.keterangan === "" || item.code === null,
        )
      ) {
        this.$notification.error({
          message: "Error pada Keluhan Utama",
          description:
            "Keluhan utama tidak boleh kosong atau memiliki komponen yang kosong.",
          duration: 5,
        })
        return
      }
      //   if (
      //     (this.riwayat_penyakit.length === 0 &&
      //       this.riwayat_penyakit_sebelumnya.length === 0) ||
      //     this.riwayat_penyakit.some(
      //       (item) =>
      //         item.display === '' || item.code === null || item.type === '',
      //     )
      //   ) {
      //     this.$notification.error({
      //       message: 'Error pada Riwayat Penyakit',
      //       description:
      //         'Riwayat penyakit tidak boleh kosong atau memiliki komponen yang kosong.',
      //       duration: 5,
      //     })
      //     return
      //   }
      //   if (
      //     (this.riwayat_pengobatan.length === 0 &&
      //       this.riwayat_pengobatan_sebelumnya.length === 0) ||
      //     this.riwayat_pengobatan.some(
      //       (item) =>
      //         item.name === '' || item.dosage === '' || item.status === '',
      //     )
      //   ) {
      //     this.$notification.error({
      //       message: 'Error pada Riwayat Pengobatan',
      //       description:
      //         'Riwayat pengobatan tidak boleh kosong atau memiliki komponen yang kosong.',
      //       duration: 5,
      //     })
      //     return
      //   }
      //   if (
      //     this.alergiTambahan.some(
      //       (item) =>
      //         item.type === null || item.display === '' || item.code === null,
      //     )
      //   ) {
      //     this.$notification.error({
      //       message: 'Error pada Riwayat Alergi',
      //       description:
      //         'Riwayat alergi tidak boleh memiliki komponen yang kosong.',
      //       duration: 5,
      //     })
      //     return
      //   }
      console.log("nyampesini?")
      this.form.validateFields((err, values) => {
        if (!err) {
          //   this.suhu = values.suhu
          this.tekanan_darah_sistolik = values.tekanan_darah_sistolik
          this.tekanan_darah_diastolik = values.tekanan_darah_diastolik
          //   this.saturasi_oksigen = values.saturasi_oksigen
          //   this.frekuensi_nafas = values.frekuensi_nafas
          //   this.nadi = values.nadi
          //   this.tinggi_badan = values.tinggi_badan
          //   this.berat_badan = values.berat_badan
          //   this.lingkar_perut = values.lingkar_perut
          this.showModal = true
        } else {
          console.log(err)
        }
      })
    },
    cancelModal() {
      this.isModalVisible = false
    },
    onCheckboxChange(e) {
      this.verifiedCheckbox = e.target.checked
    },
    async getPatientAllergy(patientId) {
      const response = await getAllergy(patientId)
      this.alergiSebelumnya = response.data.allergies
    },
    async getPatientIllnessHistory(patientId) {
      const response = await getIllnessHistory(patientId)
      this.riwayat_penyakit_sebelumnya = response.data.histories
    },
    async getPatientMedicationStatement(patientId) {
      const response = await getMedicationStatement(patientId)
      this.riwayat_pengobatan_sebelumnya = response.data.medications
      // console.log(this.riwayat_pengobatan_sebelumnya, 'riwayat pengobatan')
    },
    confirmDeleteAllergy(allergy, index, number) {
      Modal.confirm({
        title: `Apakah Anda yakin ingin menghapus alergi ${allergy.display}?`,
        content: "Aksi ini tidak dapat dibatalkan",
        okText: "Ya",
        okType: "primary",
        cancelText: "Tidak",
        onOk: async () => {
          if (number === 1) {
            await this.removePatientAllergy(allergy)
          } else {
            this.removeInput(index)
          }
        },
      })
    },
    async removePatientAllergy(allergy) {
      const response = await deleteAllergy(allergy.id)
      if (response.status === 200 || response.status === 204) {
        this.$notification.success({
          message: "Berhasil",
          description: "Data alergi berhasil dihapus",
          duration: 5,
        })
        await this.getPatientAllergy(this.patientVisitData.patient_id)
      }
    },
    confirmDeleteIllnessHistory(riwayat, index, number) {
      Modal.confirm({
        title: `Apakah Anda yakin ingin menghapus riwayat penyakit ${riwayat.display}?`,
        content: "Aksi ini tidak dapat dibatalkan",
        okText: "Ya",
        okType: "primary",
        cancelText: "Tidak",
        onOk: async () => {
          if (number === 1) {
            await this.removePatientIllnessHistory(riwayat)
          } else {
            this.removeRiwayatPenyakit(index)
          }
        },
      })
    },
    async removePatientIllnessHistory(riwayat) {
      const response = await deleteIllnessHistory(riwayat.id)
      if (response.status === 200 || response.status === 204) {
        this.$notification.success({
          message: "Berhasil",
          description: "Data riwayat penyakit berhasil dihapus",
          duration: 5,
        })
        await this.getPatientIllnessHistory(this.patientVisitData.patient_id)
      }
    },
    confirmDeleteMedicineStatement(riwayat, index, number) {
      Modal.confirm({
        title: `Apakah Anda yakin ingin menghapus riwayat pengobatan ${riwayat.name}?`,
        content: "Aksi ini tidak dapat dibatalkan",
        okText: "Ya",
        okType: "primary",
        cancelText: "Tidak",
        onOk: async () => {
          if (number === 1) {
            await this.removePatientMedicineStatement(riwayat)
          } else {
            this.removeRiwayatPengobatan(index)
          }
        },
      })
    },
    async removePatientMedicineStatement(riwayat) {
      const response = await deleteMedicationStatement(riwayat.id)
      if (response.status === 200 || response.status === 204) {
        this.$notification.success({
          message: "Berhasil",
          description: "Data riwayat pengobatan berhasil dihapus",
          duration: 5,
        })
        await this.getPatientMedicationStatement(
          this.patientVisitData.patient_id,
        )
      }
    },
    async searchMedicine(query) {
      if (query) {
        try {
          const response = await getMedications(query)
          this.list_obat = response.data.data
        } catch (error) {
          console.error("Error fetching medications:", error)
          this.list_obat = []
        }
      } else {
        this.list_obat = []
      }
    },
    async cariKeluhanUtama(query) {
      if (query) {
        try {
          const response = await searchKeluhanUtama(query)
          this.list_keluhan_utama = response.data.data
        } catch (error) {
          console.error("Error fetching keluhan utama:", error)
          this.list_keluhan_utama = []
        }
      } else {
        this.list_keluhan_utama = []
      }
    },
    async cariRiwayatPenyakit(query, index) {
      const kategori = this.riwayat_penyakit[index].type
      if (kategori === "") {
        this.$notification.error({
          message: "Gagal",
          description: "Silahkan pilih kategori terlebih dahulu",
          duration: 5,
        })
        return
      }
      if (query) {
        if (kategori === "personal") {
          try {
            const response = await searchRiwayatPribadi(query)
            this.list_riwayat_penyakit = response.data.data
          } catch (error) {
            console.error("Error fetching riwayat penyakit:", error)
            this.list_riwayat_penyakit = []
          }
        } else {
          try {
            const response = await searchRiwayatKeluarga(query)
            this.list_riwayat_penyakit = response.data.data
          } catch (error) {
            console.error("Error fetching riwayat penyakit:", error)
            this.list_riwayat_penyakit = []
          }
        }
      }
    },
    async cariAlergi(query) {
      if (query) {
        try {
          const response = await searchAlergi(query)
          this.list_alergi = response.data.data
        } catch (error) {
          console.error("Error fetching alergi:", error)
          this.list_alergi = []
        }
      } else {
        this.list_alergi = []
      }
    },
    async getPreviousMedication(patientId) {
      const response = await getById(patientId)
      const lastVisitId = response.visits.filter(
        (visit) => visit.status === "Selesai",
      )[0].id
      if (!lastVisitId) {
        return
      }
      const visitResponse = await getPatientVisit(lastVisitId)
      const medicationRequest =
        visitResponse.data.visit.DoctorNote.MedicationRequests
      for (const medication in medicationRequest) {
        this.riwayat_pengobatan.push({
          kfa: medicationRequest[medication].kfa,
          name: medicationRequest[medication].name,
          dosage: `${medicationRequest[medication].frequency} ${medicationRequest[medication].dosage}`,
          status: "",
        })
      }
    },
  },
}
</script>

<style scoped>
.text-color-green {
  color: #428a94;
}
.background-blue {
  background-color: #f6feff;
}
.file-list {
  list-style-type: none;
  margin-left: -40px;
  margin-top: 10px;
}
.list-border {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
  height: 68px;
}
.edit-file-input {
  min-width: 600px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
}

.steps {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
}

.steps .ant-steps-item-title {
  text-align: center;
}

.table thead {
  border-bottom: 1px solid #e5e5e5;
}

.table th,
.table td {
  border: none;
  padding: 8px;
}
</style>
